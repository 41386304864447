export const environment = {
  production: true,
  firebase: {
    projectId: 'enroll-here',
    appId: '1:68638839047:web:707656c34b25f8ecfd35a2',
    storageBucket: 'enroll-here.appspot.com',
    apiKey: 'AIzaSyBR9wal5Ti1JLJvOdx3t4vAgYFjW_EnW3g',
    authDomain: 'enroll-here.firebaseapp.com',
    messagingSenderId: '68638839047',
    measurementId: 'G-T395RYSRFD',
    appCheckRecaptchaSiteKey: '6LcV45wpAAAAAGn2csqk4t-Kbae-iLesljwvmM0S',
  },
  googleMapsApiKey: 'AIzaSyBR9wal5Ti1JLJvOdx3t4vAgYFjW_EnW3g',
  allowedPostLoginTargets: [
    'https://enrollhere.com',
    'https://enroll-here-landing.web.app',
  ],
  homepage: 'https://enrollhere.com',
  isDemo: false,
  elasticHttp: 'https://enrollhere.es.us-east1.gcp.elastic-cloud.com/',
  elasticPrefix: 'enrollhere_',
  devVersion: '{BUILD_VERSION}',
  sentry: {
    dsn: 'https://edda11464f7ff219d68e15c9b41290ad@o4506558808064000.ingest.us.sentry.io/4506700804849664',
    tracePropagationTargets: [/^https:\/\/app\.enrollhere\.com/],
    environment: 'production',
  },
};
